<template>
  <div class="py-4">
    <hadeeth-list :loading="loading.items" :ids="items"></hadeeth-list>
  </div>
</template>

<script>
import HadeethList from "@/components/HadeethComponents/HadeethList.vue";
export default {
  name:"cartLink",
  components: { HadeethList },
  data: () => ({
    items: [],
    loading: {
      items: true,
    },
  }),
  async mounted() {
    const fullPath = this.$route.fullPath;
    if (!fullPath.includes("lang=")) {
      this.$router.push({
        name: "cart_link",
        query: {
          lang: this.$root.locale,
        },
      });
    }
    await this.getItems();
  },
  computed: {
    currentLocale() {
      return this.$root.locale;
    },
    queryCode() {
      return this.$route.query.code;
    },
  },
  watch: {},
  methods: {
    async getItems() {
      this.$set(this, "items", []);
      this.$set(this.loading, "items", true);
      const queryCode = this.queryCode;
      const cart = await this.$root.platformManager.getDaawaCart(queryCode);
      const ids = cart.ids.filter((id) => !!id);
      this.$set(this, "items", ids);
      this.$set(this.loading, "items", false);
    },
  },
};
</script>
