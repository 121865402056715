<template>
  <div>
    <v-card v-if="title" class="mb-4">
      <v-card-title>
        {{ title }}
      </v-card-title>
    </v-card>
    <template v-if="loading || internalLoading">
      <template v-for="i in loadingItems">
        <v-skeleton-loader
          :key="i"
          class="mb-4"
          type="card"
        ></v-skeleton-loader>
      </template>
    </template>
    <template v-else-if="items.length > 0">
      <template v-for="(item, i) in items">
        <hadeeth-async
          :key="`${i}-${item.id}`"
          :loading="!item.title"
          :hadeeth="item"
        ></hadeeth-async>
      </template>
    </template>
    <template v-else>
      <v-layout class="pt-8" column align-center justify-center>
        <v-icon size="75" color="grey">mdi-file-multiple-outline</v-icon>
        <p class="mt-6">
          {{ $t("no_hadeeths") }}
        </p>
      </v-layout>
    </template>
  </div>
</template>

<script>
import HadeethAsync from "@/components/HadeethComponents/HadeethAsync.vue";
export default {
  components: {
    HadeethAsync,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    ids: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    internalLoading: false,
    items: [],
  }),
  computed: {
    currentLocale() {
      return this.$root.locale;
    },
    loadingItems() {
      return this.ids?.length || 4;
    },
  },
  watch: {
    async ids() {
      await this.getItems();
    },
  },
  async mounted() {
    await this.getItems();
  },
  methods: {
    async getItems() {
      this.$set(this, "items", []);
      this.$set(this, "internalLoading", true);
      const ids = this.ids;
      if (ids.length) {
        const response = await this.$root.platformManager.pickHadeeths(
          ids,
          this.currentLocale
        );
        this.$set(this, "items", response.data);
      }
      this.$set(this, "internalLoading", false);
    },
  },
};
</script>
