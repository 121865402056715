<template>
  <div class="py-4">
    <hadeeth-list
      :loading="loading"
      :ids="items"
      :title="`${$t('bookmarks')} (${items.length})`"
    ></hadeeth-list>
  </div>
</template>

<script>
import BookmarksMixin from "@/mixins/bookmarks.js";
import HadeethList from "@/components/HadeethComponents/HadeethList.vue";
export default {
  mixins: [BookmarksMixin],
  components: {
    HadeethList,
  },
  data: () => ({
    loading: false,
    items: [],
  }),
  async mounted() {
    await this.getItems();
  },
  methods: {
    async getItems() {
      this.$set(this, "items", []);
      this.$set(this, "loading", true);
      const items = await this.getBookmarks();
      const ids = items.map((item) => item.id).filter((id) => !!id);
      this.$set(this, "items", ids);
      this.$set(this, "loading", false);
    },
  },
};
</script>
