import MobileLanguagesManager from "../drivers/MobileApi/LanguagesManager.js";
import MobileLanguagesFormatter from "../core/formatters/MobileApi/LanguagesFormatter.js";

import MobileCategoriesManager from "../drivers/MobileApi/CategoriesManager.js";
import MobileCategoriesFormatter from "../core/formatters/MobileApi/CategoriesFormatter.js";

import MobileHadeethsManager from "../drivers/MobileApi/HadeethsManager.js";
import MobileHadeethsFormatter from "../core/formatters/MobileApi/HadeethsFormatter.js";

import DaawaCartManager from "../../data-service/drivers/WebApi/DaawaCartManager.js";

export default {
  share(txt) {
    window.plugins.socialsharing.share(txt);
  },
  getLocale() {
    window.localStorage.getItem("lang") || "ar";
  },

  changeLocale(isoCode) {
    window.localStorage.setItem("lang", isoCode);
  },

  fetchLanguages() {
    return MobileLanguagesManager.fetchAll();
  },

  formatLanguagesResponse(response) {
    return MobileLanguagesFormatter.formatList(response);
  },

  fetchLanguageRootCategories(isoCode) {
    return MobileCategoriesManager.fetchLanguageRootCategories(isoCode);
  },

  formatLanguageRootCategoriesResponse(response) {
    return MobileCategoriesFormatter.formatList(response);
  },

  fetchHadeeths(categoryId, isoCode, page, perPage) {
    return MobileHadeethsManager.fetchHadeeths(
      isoCode,
      categoryId,
      page,
      perPage
    );
  },

  formatHadeethsResponse(response) {
    return MobileHadeethsFormatter.formatList(response);
  },

  fetchSingleHadeeth(hadeethId, languageIsoCode) {
    return MobileHadeethsManager.fetchSingleHadeeth(languageIsoCode, hadeethId);
  },

  formatSingleHadeeth(response) {
    return MobileHadeethsFormatter.formatSingleHadeeth(response);
  },

  fetchSubCategories(categoryId, languageIsoCode) {
    return MobileCategoriesManager.fetchAllSubCategories(languageIsoCode);
  },

  formatSubCategories(response) {
    return MobileCategoriesFormatter.formatSubCategories(response);
  },

  fetchHadeethSearchResults(searchPhrase, languageIsoCode) {
    return MobileHadeethsManager.fetchSearchResults(
      languageIsoCode,
      searchPhrase
    );
  },

  formatHadeethSearchResults(response) {
    return MobileHadeethsFormatter.formatSearchResults(response);
  },

  async setDaawaCart(ids = [], lang = "") {
    return await DaawaCartManager.setDaawaCart(ids, lang);
  },

  async getDaawaCart(id = "") {
    return await DaawaCartManager.getDaawaCart(id);
  },

  async pickHadeeths(ids = [], lang = "") {
    return await DaawaCartManager.pickHadeeths(ids, lang);
  },
};
