export default {
  methods: {
    addVisitCounter() {
      const id = this.$route.query.hadeeth;
      const lang = this.$root.locale;
      const path = `${id}_${lang}`;
      return new Promise((resolve) => {
        if (id && lang) {
          const foragePath = `visit-counter/${path}`;
          const item = {
            id,
            lang,
            path,
            lastVisit: new Date(),
            counter: 0,
          };
          return this.$getItem(foragePath)
            .then(async (offlineItem) => {
              const newItem = offlineItem || item;
              newItem.counter += 1;
              try {
                await this.$setItem(foragePath, newItem);
                await this.updateVisitCounterPaths(foragePath);
                resolve(true);
              } catch (forageError) {
                resolve(false);
              }
            })
            .catch(() => {
              resolve(true);
            });
        } else {
          resolve(true);
        }
      });
    },
    updateVisitCounterPaths(path = null) {
      return new Promise((resolve) => {
        if (path) {
          const item = [];
          const countersPath = "visit-counter-paths";
          return this.$getItem(countersPath)
            .then(async (offlineItem) => {
              const newItem = offlineItem || item;
              if (newItem.includes(path)) {
                resolve(true);
              } else {
                newItem.push(path);
                try {
                  await this.$setItem(countersPath, newItem);
                  resolve(true);
                } catch (forageError) {
                  resolve(false);
                }
              }
            })
            .catch(() => {
              resolve(true);
            });
        } else {
          resolve(false);
        }
      });
    },
    getVisitCounters(max = 10) {
      const countersPath = "visit-counter-paths";
      return new Promise((resolve, reject) => {
        return this.$getItem(countersPath)
          .then(async (pathList) => {
            if (!pathList) {
              resolve([]);
            } else {
              try {
                const items = [];
                for (let i = 0; i < pathList.length; i++) {
                  const path = pathList[i];
                  const item = await this.$getItem(path);
                  if (item) {
                    items.push(item);
                  }
                }
                if (items.length === 0) {
                  resolve(items);
                } else {
                  const itemsObj = {};
                  const mostVisistedSorted = [...items].sort(
                    (a, b) => b.counter - a.counter
                  );
                  itemsObj.mostVisited = mostVisistedSorted.splice(0, max);
                  const latestVisitedSorted = [...items].sort(
                    (a, b) => new Date(b.lastVisit) - new Date(a.lastVisit)
                  );
                  itemsObj.latestVisited = latestVisitedSorted.splice(0, max);
                  resolve(itemsObj);
                }
              } catch (forageError) {
                reject([]);
              }
            }
          })
          .catch(() => {
            resolve([]);
          });
      });
    },
  },
};
