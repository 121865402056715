import { EventBus } from "@/event-bus.js";

export default {
  methods: {
    getDaawaCartLength() {
      const daawaCartPath = "daawa-cart-paths";
      return new Promise((resolve) => {
        return this.$getItem(daawaCartPath)
          .then(async (pathList) => {
            const length = pathList ? pathList.length : 0;
            resolve(length);
          })
          .catch(() => {
            resolve(0);
          });
      });
    },
    getDaawaCart(max = 0) {
      const daawaCartPath = "daawa-cart-paths";
      return new Promise((resolve, reject) => {
        return this.$getItem(daawaCartPath)
          .then(async (pathList) => {
            if (!pathList) {
              resolve([]);
            } else {
              try {
                const items = [];
                for (let i = 0; i < pathList.length; i++) {
                  const path = pathList[i];
                  const item = await this.$getItem(path);
                  if (item) {
                    items.push(item);
                  }
                }
                if (items.length === 0) {
                  resolve(items);
                } else {
                  const sortedItems = items.sort(
                    (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
                  );
                  const croppedItems =
                    max && parseInt(max) > 0
                      ? sortedItems.splice(0, max)
                      : sortedItems;
                  resolve(croppedItems);
                }
              } catch (forageError) {
                reject([]);
              }
            }
          })
          .catch(() => {
            resolve([]);
          });
      });
    },
    daawaCartGetLinks() {
      const daawaCartPath = "daawa-cart-links-paths";
      return new Promise((resolve, reject) => {
        return this.$getItem(daawaCartPath)
          .then(async (pathList) => {
            if (!pathList) {
              resolve([]);
            } else {
              try {
                const items = [];
                for (let i = 0; i < pathList.length; i++) {
                  const path = pathList[i];
                  const item = await this.$getItem(path);
                  if (item) {
                    items.push(item);
                  }
                }
                if (items.length === 0) {
                  resolve(items);
                } else {
                  const sortedItems = items.sort(
                    (a, b) => new Date(b.date) - new Date(a.date)
                  );
                  resolve(sortedItems);
                }
              } catch (forageError) {
                reject([]);
              }
            }
          })
          .catch(() => {
            resolve([]);
          });
      });
    },
    updateDaawaCartLinks(path = null) {
      return new Promise((resolve) => {
        if (path) {
          const item = [];
          const daawaCartPath = "daawa-cart-links-paths";
          return this.$getItem(daawaCartPath)
            .then(async (offlineItem) => {
              const newItem = offlineItem || item;
              if (newItem.includes(path)) {
                resolve(true);
              } else {
                newItem.push(path);
                try {
                  await this.$setItem(daawaCartPath, newItem);
                  EventBus.$emit("daawaCartLinksUpdated");
                  resolve(true);
                } catch (forageError) {
                  resolve(false);
                }
              }
            })
            .catch(() => {
              resolve(true);
            });
        } else {
          resolve(false);
        }
      });
    },
    daawaCartAddLink(item) {
      return new Promise((resolve) => {
        const salt = Math.ceil(Math.random() * 999999);
        const foragePath = `daawa-cart-link/${item.id}-${salt}`;
        return this.$getItem(foragePath)
          .then(async (offlineItem) => {
            if (offlineItem) {
              return resolve(false);
            }
            try {
              await this.$setItem(foragePath, item);
              await this.updateDaawaCartLinks(foragePath);
              EventBus.$emit("daawaCartLinksUpdated");
              this.resetDaawaCart();
              resolve(true);
            } catch (forageError) {
              resolve(false);
            }
          })
          .catch(() => {
            resolve(true);
          });
      });
    },
    resetDaawaCart() {
      const daawaCartPath = "daawa-cart-paths";
      return new Promise((resolve, reject) => {
        return this.$getItem(daawaCartPath)
          .then(async (pathList) => {
            if (!pathList) {
              resolve([]);
            } else {
              try {
                for (let i = 0; i < pathList.length; i++) {
                  const path = pathList[i];
                  await this.$removeItem(path);
                }
                await this.$removeItem(daawaCartPath);
                EventBus.$emit("daawaCartUpdated");
                resolve(true);
              } catch (forageError) {
                reject([]);
              }
            }
          })
          .catch(() => {
            resolve([]);
          });
      });
    },
  },
};
