const axios = require("axios");

export default {
  fetchHadeeths(categoryId, isoCode, page, perPage) {
    let baseUrl = process.env.VUE_APP_API_BASE_URL + "/hadeeths";
    return axios.get(
      baseUrl +
        "/list/" +
        "?category_id=" +
        categoryId +
        "&language=" +
        isoCode +
        "&page=" +
        page +
        "&per_page=" +
        perPage
    );
  },

  fetchSingleHadeeth(hadeethId, languageIsoCode) {
    let baseUrl = process.env.VUE_APP_API_BASE_URL + "/hadeeths";

    return axios.get(
      baseUrl +
        "/one/" +
        "?id=" +
        hadeethId +
        "&language=" +
        languageIsoCode +
        "&with_ar=1"
    );
  },

  fetchSearchResults(searchPhrase, languageIsoCode) {
    let baseUrl = process.env.VUE_APP_API_BASE_URL + "/hadeeths";
    //console.log(searchPhrase);
    return axios.get(
      baseUrl +
        "/search/" +
        "?search_phrase=" +
        searchPhrase +
        "&language=" +
        languageIsoCode
    );
  },
};
