import { EventBus } from "@/event-bus.js";

export default {
  data: () => ({
    inBookmarks: false,
  }),
  computed: {
    hadeethIdToUse() {
      const queryId = this.$route.query.hadeeth;
      const localId = this.hadeethId;
      return queryId || localId;
    },
    currentLocale() {
      const queryLocale = this.$route.query.lang;
      const rootLang = this.$root.locale;
      return queryLocale || rootLang;
    },
  },
  mounted() {
    this.checkBookmarksItemStatus();
  },
  methods: {
    async checkBookmarksItemStatus() {
      const exists = await this.checkBookmarksItem(this.hadeethIdToUse);
      this.$set(this, "inBookmarks", exists);
    },
    async toggleBookmarksItem() {
      const id = this.hadeethIdToUse;
      if (this.inBookmarks) {
        await this.removeBookmarksItem(id);
        this.checkBookmarksItemStatus();
      } else {
        await this.addBookmarksItem(id);
        this.checkBookmarksItemStatus();
      }
    },
    checkBookmarksItem(id = "") {
      const lang = this.$root.locale;
      const path = `${id}_${lang}`;
      return new Promise((resolve) => {
        if (id && lang) {
          const foragePath = `bookmarks/${path}`;
          return this.$getItem(foragePath)
            .then(async (offlineItem) => {
              if (offlineItem) {
                return resolve(true);
              } else {
                return resolve(false);
              }
            })
            .catch(() => {
              resolve(false);
            });
        } else {
          resolve(false);
        }
      });
    },
    addBookmarksItem(id = "") {
      const lang = this.$root.locale;
      const path = `${id}_${lang}`;
      return new Promise((resolve) => {
        if (id && lang) {
          const item = {
            path,
            id,
            lang,
            createdDate: new Date(),
          };
          const foragePath = `bookmarks/${path}`;
          return this.$getItem(foragePath)
            .then(async (offlineItem) => {
              if (offlineItem) {
                return resolve(false);
              }
              try {
                await this.$setItem(foragePath, item);
                await this.updateBookmarks(foragePath);
                EventBus.$emit("bookmarksUpdated");
                resolve(true);
              } catch (forageError) {
                resolve(false);
              }
            })
            .catch(() => {
              resolve(true);
            });
        } else {
          resolve(true);
        }
      });
    },
    removeBookmarksItem(id = "") {
      const lang = this.$root.locale;
      const path = `${id}_${lang}`;
      return new Promise((resolve) => {
        const foragePath = `bookmarks/${path}`;
        const bookmarksPath = "bookmarks-paths";
        return this.$getItem(bookmarksPath)
          .then(async (offlineItem) => {
            if (
              offlineItem &&
              Array.isArray(offlineItem) &&
              offlineItem.includes(foragePath)
            ) {
              const index = offlineItem.indexOf(foragePath);
              offlineItem.splice(index, 1);
              try {
                await this.$setItem(bookmarksPath, offlineItem);
                await this.$removeItem(foragePath);
                EventBus.$emit("bookmarksUpdated");
                resolve(true);
              } catch (forageError) {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch(() => {
            resolve(true);
          });
      });
    },
    updateBookmarks(path = null) {
      return new Promise((resolve) => {
        if (path) {
          const item = [];
          const bookmarksPath = "bookmarks-paths";
          return this.$getItem(bookmarksPath)
            .then(async (offlineItem) => {
              const newItem = offlineItem || item;
              if (newItem.includes(path)) {
                resolve(true);
              } else {
                newItem.push(path);
                try {
                  await this.$setItem(bookmarksPath, newItem);
                  EventBus.$emit("bookmarksUpdated");
                  resolve(true);
                } catch (forageError) {
                  resolve(false);
                }
              }
            })
            .catch(() => {
              resolve(true);
            });
        } else {
          resolve(false);
        }
      });
    },
  },
};
