<template>
  <div class="py-4">
    <hadeeth-list
      :loading="loading"
      :ids="items.latestVisited"
      :title="`${$t('latest-visited')}`"
      class="mb-8"
    ></hadeeth-list>
    <hadeeth-list
      v-if="items.mostVisited.length"
      :loading="loading"
      :ids="items.mostVisited"
      :title="`${$t('most-visited')}`"
    ></hadeeth-list>
  </div>
</template>

<script>
import MostVisitedMixin from "@/mixins/most-visited.js";
import HadeethList from "@/components/HadeethComponents/HadeethList.vue";
export default {
  mixins: [MostVisitedMixin],
  components: {
    HadeethList,
  },
  data: () => ({
    loading: false,
    items: {
      latestVisited: [],
      mostVisited: [],
    },
  }),
  async mounted() {
    const fullPath = this.$route.fullPath;
    if (!fullPath.includes("lang=")) {
      this.$router.push({
        name: "latest_visited",
        query: {
          lang: this.$root.locale,
        },
      });
    }
    await this.getItems();
  },
  computed: {
    currentLocale() {
      return this.$root.locale;
    },
  },
  methods: {
    async getItems() {
      this.$set(this, "loading", true);
      this.getVisitCounters(10)
        .then((items) => {
          const latestVisited = items.latestVisited.map((item) => item.id);
          const mostVisited = items.mostVisited
            .map((item) => item.id)
            .filter((id) => !latestVisited.includes(id));
          this.$set(this.items, "latestVisited", latestVisited);
          this.$set(this.items, "mostVisited", mostVisited);
        })
        .catch(() => {
          //
        })
        .finally(() => {
          this.$set(this, "loading", false);
        });
    },
  },
};
</script>
