<template>
    <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('refs') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
            <p v-html="refs"></p>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
    props: ['references'],
    computed:{
        refs(){
            return '<li><small>'+this.references.replace(/\n/g,"</li><li>")+'</small></li>';
        }
    }
}
</script>