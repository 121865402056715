<template>
    <v-card color="bg lighten-4">
        <v-card-text>
            <ol class="mx-3">
                <li v-for="(hint, i) in hints" :key="'hint_ar' + i" class="my-2"><p><b class="type--text">{{ hint }}</b></p></li>
            </ol>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: ['hints']
}
</script>