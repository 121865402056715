<template>
  <div v-if="loading || hadeeth">
    <v-skeleton-loader
      v-if="loading"
      class="mb-4"
      type="card"
    ></v-skeleton-loader>
    <hadeeth-excerpt v-else :hadeeth="hadeeth"></hadeeth-excerpt>
  </div>
</template>

<script>
import HadeethExcerpt from "@/components/HadeethExcerpt.vue";
export default {
  props: {
    hadeeth: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    HadeethExcerpt,
  },
  data: () => ({}),
  mounted() {},
  methods: {},
};
</script>
