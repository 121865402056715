<template>
  <div>
    <v-alert class="mt-3" border="top" color="drawerFixed" colored-border>
      <b class="type--text">{{ $t("main_cats") }}</b>
    </v-alert>
    <div class="text-center" v-if="fetching">
      <v-progress-circular
        class="my-4"
        color="lime darken-4"
        indeterminate
        rounded
        size="60"
      ></v-progress-circular>
    </div>

    <v-scale-transition>
      <v-card
        dense
        elevation="3"
        color="bg lighten-5"
        class="mx-auto px-4 py-1 mb-5"
        v-if="categories.length > 0"
      >
        <v-list class="my-3" one-line rounded color="bg lighten-5">
          <template v-for="(category, i) in categories">
            <v-list-item
              class="my-2"
              :key="'cat' + i"
              @click="showCategory(category)"
            >
              <v-list-item-content>
                <v-list-item-title
                  ><b class="type--text">{{
                    category.title
                  }}</b></v-list-item-title
                >
                <v-list-item-subtitle class="mt-2"
                  ><v-chip small label color="bg lighten-3"
                    ><v-icon small color="lime darken-2">mdi-view-list</v-icon>:
                    {{ category.hadeeths_count }}</v-chip
                  ></v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-icon small color="lime darken-2">{{
                  $vuetify.rtl ? "mdi-arrow-left" : "mdi-arrow-right"
                }}</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider
              :key="`divider-${i}`"
              v-if="i < categories.length - 1"
            ></v-divider>
          </template>
        </v-list>
      </v-card>
    </v-scale-transition>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  name: "categoriesView",
  data() {
    return {
      categories: [],
      fetching: true,
    };
  },

  mounted() {
    //after the main language has been set

    this.fetchCategories(this.$root.locale);
  },

  watch: {
    "$root.locale"(val) {
      return; // no need for watchers as we use keep-alive and configured to differentiate between every lang, so the mounted will fire for every lang change
      setTimeout(() => {
        this.fetchCategories(val);
      }, 300);
    },
  },

  methods: {
    fetchCategories(val) {
      console.log("FETCHING categories AT locale:" + val);
      let currentLocale = val; //this.$root.locale;
      this.$root.platformManager
        .fetchLanguageRootCategories(val)
        .then((response) => {
          this.categories = this.$root.platformManager.formatLanguageRootCategoriesResponse(
            response
          );
          this.fetching = false;
        })
        .catch((error) => {
          console.log("error fetching categories", error);
          this.fetching = false;
        });
    },
    showCategory(category) {
      this.$router.push({
        name: "hadeeths",
        query: {
          lang: this.$root.locale,
          cat_id: category.id,
        },
      });
    },
  },

  computed: {},
};
</script>

<style scoped></style>
