<template>
  <div class="py-4">
    <v-row>
      
      <v-col cols="12" lg="4">
        <v-card>
          <v-card-title>
            {{ $t("daawa-links") }}
            <template v-if="links.length"> ({{ links.length }}) </template>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              :disabled="items.length === 0"
              @click="newLinkDialog.model = true"
              
            >
              <v-icon>mdi-plus</v-icon> {{$t("generate-link")}}
            </v-btn>
          </v-card-title>
          <v-card-text class="px-0">
            <v-list v-if="links.length > 0">
              <template v-for="(link, i) in links">
                <v-list-item :key="i" @click="copyLink(link)">
                  <v-list-item-avatar
                    :color="link.copying ? 'success' : ''"
                    class="d-flex justify-center"
                  >
                    <v-fade-transition group hide-on-leave>
                        <v-icon v-if="link.copying" key="check-icon" dark>
                          mdi-check
                        </v-icon>
                        <v-icon v-else key="link-icon">
                          mdi-content-copy
                        </v-icon>
                    </v-fade-transition>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <template v-if="link.title">
                        {{ link.title }} <v-chip class="ms-2" small dark color="green" v-if="link.copying">{{$t("link-copied")}}</v-chip>
                      </template>
                      <i v-else class="grey--text"
                        >-- {{ $t("untitled") }} --</i
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ link.date | moment("from", "now") }}
                      <template v-if="link.itemsLength">
                        - {{ $t("contains") }}
                        <template v-if="currentLocale === 'ar'">
                          <!--
                            No need to translate the following words
                            __ because this <template> is for `ar` only
                          -->
                          {{
                            link.itemsLength
                              | arNumSuffix(
                                "حديث واحد",
                                "حديثين",
                                "حديثاً",
                                "أحاديث"
                              )
                          }}
                        </template>
                        <template v-else>
                          {{ link.itemsLength }} {{ $t("hadeeth") }}
                        </template>
                      </template>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <div v-else class="text-center px-6 py-2">
              <div>
                <v-icon color="grey" size="50">mdi-link</v-icon>
              </div>
              <div>{{ $t("no-links-created-until-now") }}.</div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="8">
        <hadeeth-list :loading="loading.items" :ids="items"></hadeeth-list>
      </v-col>      
    </v-row>
    <v-dialog
      v-model="newLinkDialog.model"
      scrollable
      max-width="450"
      persistent
    >
      <v-card
        :disabled="newLinkDialog.loading"
        :loading="newLinkDialog.loading"
      >
        <template v-if="newLinkDialog.complete">
          <v-card-text class="pt-8 pb-8 text-center">
            <div>
              <lottie-animation
                path="check-okey-done"
                auto-play
                :loop="false"
                :width="140"
                :height="140"
              ></lottie-animation>
            </div>
            <v-card-title class="d-block">
              {{ $t("link-is-ready") }}
            </v-card-title>
            <div>
              <v-list-item
                style="border: 2px dashed var(--v-success-base)"
                @click="copyLink(newLinkDialog.link, true)"
              >
                <v-list-item-content>
                  <v-list-item-subtitle class="text-start">
                    {{ $t("click-to-copy-link") }}
                  </v-list-item-subtitle>
                  <v-list-item-title
                    v-text="newLinkDialog.link.url"
                  ></v-list-item-title>
                </v-list-item-content>
                <v-list-item-avatar
                  tile
                  class="rounded-sm"
                  size="56"
                  :color="
                    newLinkDialog.link.copying ? 'success' : 'transparent'
                  "
                >
                  <v-slide-x-transition>
                    <v-icon v-if="newLinkDialog.link.copying" dark>
                      mdi-check
                    </v-icon>
                  </v-slide-x-transition>
                </v-list-item-avatar>
              </v-list-item>
            </div>
            <div class="pt-7">
              <v-btn
                text
                large
                dark
                color="drawerFixed"
                @click="newLinkDialog.model = false"
              >
                {{ $t("continue") }}
                <v-icon class="ms-2 ltr-flip">mdi-keyboard-backspace</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </template>
        <template v-else>
          <v-card-title>
            {{ $t("generate-new-link") }}
            <v-spacer></v-spacer>
            <v-btn icon @click="newLinkDialog.model = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle class="mt-0">
            {{ $t("generate-new-link-desc") }}
          </v-card-subtitle>
          <v-card-text>
            <v-text-field
              v-model="newLinkDialog.link.title"
              filled
              color="drawerFixed"
              rounded
              class="rounded-sm mb-3"
              :label="$t('the-title')"
              :hint="$t('daawa-cart-link-title-desc')"
              persistent-hint
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="px-6 pb-4">
            <v-spacer></v-spacer>
            <v-btn
              color="drawerFixed"
              text
              large
              dark
              class="px-4"
              @click="newLinkDialog.model = false"
            >
              {{ $t("back") }}
            </v-btn>
            <v-btn
              color="drawerFixed"
              dark
              large
              class="px-6"
              @click="generateLink()"
            >
              {{ $t("generate-link") }}
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import HadeethList from "@/components/HadeethComponents/HadeethList.vue";
export default {
  components: { HadeethList },
  data: () => ({
    items: [],
    links: [],
    loading: {
      items: true,
      links: true,
    },
    newLinkDialog: {
      model: false,
      loading: false,
      complete: false,
      link: {
        id: "",
        title: "",
        lang: "",
        url: "",
        itemsLength: 0,
        date: null,
        copying: false,
      },
    },
  }),
  async mounted() {
    const fullPath = this.$route.fullPath;
    if (!fullPath.includes("lang=")) {
      this.$router.push({
        name: "cart",
        query: {
          lang: this.$root.locale,
        },
      });
    }
    await this.getItems();
    await this.getLinks();
    EventBus.$on("daawaCartUpdated", () => {
      this.getItems();
    });
    EventBus.$on("daawaCartLinksUpdated", () => {
      this.getLinks();
    });
  },
  computed: {
    currentLocale() {
      return this.$root.locale;
    },
  },
  watch: {
    "newLinkDialog.model"(v) {
      if (!v) {
        this.$set(this.newLinkDialog, "loading", false);
        this.$set(this.newLinkDialog, "complete", false);
        this.resetNewLink();
      }
    },
  },
  methods: {
    resetNewLink() {
      this.$set(this.newLinkDialog.link, "id", "");
      this.$set(this.newLinkDialog.link, "title", "");
      this.$set(this.newLinkDialog.link, "lang", "");
      this.$set(this.newLinkDialog.link, "url", "");
      this.$set(this.newLinkDialog.link, "itemsLength", 0);
      this.$set(this.newLinkDialog.link, "date", null);
      this.$set(this.newLinkDialog.link, "copying", false);
    },
    async getItems() {
      this.$set(this, "items", []);
      this.$set(this.loading, "items", true);
      const items = await this.getDaawaCart();
      const ids = items.map((item) => item.id);
      this.$set(this, "items", ids);
      this.$set(this.loading, "items", false);
    },
    async getLinks() {
      this.$set(this, "links", []);
      this.$set(this.loading, "links", true);
      const links = await this.daawaCartGetLinks();
      this.$set(this, "links", links);
      this.$set(this.loading, "links", false);
    },
    async generateLink() {
      this.$set(this.newLinkDialog, "loading", true);
      const link = this.newLinkDialog.link;
      link.title = link.title?.trim();
      const ids = this.items;
      link.itemsLength = ids.length;
      link.lang = this.$root.locale;
      link.date = new Date();
      link.copying = false;
      link.id = await this.getDaawaCartId(ids, link.lang);
      link.url = await this.getUrl(link);
      await this.daawaCartAddLink(link);
      this.$set(this.newLinkDialog, "loading", false);
      this.$set(this.newLinkDialog, "complete", true);
    },
    async getUrl(link) {
      const baseUrl = 'https://hadeethenc.com/redir-daawa';
      const url = `${baseUrl}/?lang=${link.lang}&code=${link.id}`;
      return url;
    },
    async getDaawaCartId(ids = [], lang = "") {
      try {
        const response = await this.$root.platformManager.setDaawaCart(
          ids,
          lang
        );
        const id = response.cart_id;
        return id;
      } catch (err) {
        console.log(err);
      }
    },
    copyLink(link, isDialog = false) {
      link.copying = true;
      let copyText;
      if (isDialog) {
        const container = document.querySelector(".v-dialog");
        copyText = this.$copyText(link.url, container);
      } else {
        copyText = this.$copyText(link.url);
      }
      copyText.then(function() {
        setTimeout(() => {
          link.copying = false;
        }, 2000);
      });
    },
  },
};
</script>
