<template>
  <v-btn
    :small="!inBottomNav"
    :outlined="!inBottomNav"
    :color="value ? 'lime darken-3' : inBottomNav ? '' : 'grey darken-1'"
    class="bookmark-btn"
    :class="{ 'in-bottom-nav': inBottomNav }"
    @click.stop="$emit('toggle')"
  >
    <v-icon :small="!inBottomNav">{{
      value ? "mdi-bookmark" : "mdi-bookmark-outline"
    }}</v-icon>
    <small v-if="inBottomNav">
      {{ $t('bookmarks') }}
    </small>
  </v-btn>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    inBottomNav: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
};
</script>

<style lang="scss">
.bookmark-btn {
  &.in-bottom-nav {
    .v-btn__content {
      flex-direction: column !important;
    }
  }
}
</style>
