import { EventBus } from "@/event-bus.js";

export default {
  data: () => ({
    inDaawaCart: false,
  }),
  computed: {
    hadeethIdToUse() {
      const queryId = this.$route.query.hadeeth;
      const localId = this.hadeethId;
      return queryId || localId;
    },
    currentLocale() {
      const queryLocale = this.$route.query.lang;
      const rootLang = this.$root.locale;
      return queryLocale || rootLang;
    },
  },
  mounted() {
    this.checkDaawaCartItemStatus();
  },
  activated() {
    this.checkDaawaCartItemStatus();
  },
  methods: {
    async checkDaawaCartItemStatus() {
      const exists = await this.checkDaawaCartItem();
      this.$set(this, "inDaawaCart", exists);
    },
    async toggleDaawaCartItem() {
      if (this.inDaawaCart) {
        await this.removeDaawaCartItem();
        this.checkDaawaCartItemStatus();
      } else {
        await this.addDaawaCartItem();
        this.checkDaawaCartItemStatus();
      }
    },
    // Links
    resetDaawaCartLinks() {
      const daawaCartPath = "daawa-cart-links-paths";
      return new Promise((resolve, reject) => {
        return this.$getItem(daawaCartPath)
          .then(async (pathList) => {
            if (!pathList) {
              resolve([]);
            } else {
              try {
                for (let i = 0; i < pathList.length; i++) {
                  const path = pathList[i];
                  await this.$removeItem(path);
                }
                await this.$removeItem(daawaCartPath);
                EventBus.$emit("daawaCartLinksUpdated");
                resolve(true);
              } catch (forageError) {
                reject([]);
              }
            }
          })
          .catch(() => {
            resolve([]);
          });
      });
    },
    // ../end Links
    addDaawaCartItem() {
      return new Promise((resolve) => {
        const id = this.hadeethIdToUse;
        const item = {
          id,
          lang: this.currentLocale,
          createdDate: new Date(),
        };
        const foragePath = `daawa-cart/${id}`;
        return this.$getItem(foragePath)
          .then(async (offlineItem) => {
            if (offlineItem) {
              return resolve(false);
            }
            try {
              await this.$setItem(foragePath, item);
              await this.updateDaawaCart(foragePath);
              EventBus.$emit("daawaCartUpdated");
              resolve(true);
            } catch (forageError) {
              resolve(false);
            }
          })
          .catch(() => {
            resolve(true);
          });
      });
    },
    checkDaawaCartItem() {
      return new Promise((resolve) => {
        const id = this.hadeethIdToUse;
        const foragePath = `daawa-cart/${id}`;
        return this.$getItem(foragePath)
          .then(async (offlineItem) => {
            if (offlineItem) {
              return resolve(true);
            } else {
              return resolve(false);
            }
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    updateDaawaCart(path = null) {
      return new Promise((resolve) => {
        if (path) {
          const item = [];
          const daawaCartPath = "daawa-cart-paths";
          return this.$getItem(daawaCartPath)
            .then(async (offlineItem) => {
              const newItem = offlineItem || item;
              if (newItem.includes(path)) {
                resolve(true);
              } else {
                newItem.push(path);
                try {
                  await this.$setItem(daawaCartPath, newItem);
                  EventBus.$emit("daawaCartUpdated");
                  resolve(true);
                } catch (forageError) {
                  resolve(false);
                }
              }
            })
            .catch(() => {
              resolve(true);
            });
        } else {
          resolve(false);
        }
      });
    },
    removeDaawaCartItem() {
      return new Promise((resolve) => {
        const id = this.hadeethIdToUse;
        const foragePath = `daawa-cart/${id}`;
        const daawaCartPath = "daawa-cart-paths";
        return this.$getItem(daawaCartPath)
          .then(async (offlineItem) => {
            if (
              offlineItem &&
              Array.isArray(offlineItem) &&
              offlineItem.includes(foragePath)
            ) {
              const index = offlineItem.indexOf(foragePath);
              offlineItem.splice(index, 1);
              try {
                await this.$setItem(daawaCartPath, offlineItem);
                await this.$removeItem(foragePath);
                EventBus.$emit("daawaCartUpdated");
                resolve(true);
              } catch (forageError) {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch(() => {
            resolve(true);
          });
      });
    },
  },
};
