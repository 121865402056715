<template>
  <div class="my-5" style="padding-bottom:70px">
    <v-bottom-navigation
      v-if="hadeeth"
      fixed
      dense
      height="50"
      v-model="bottom"

    >
      
            <v-menu top :offset-y="true">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="ms-4"
                    >
                    <small>{{$t("fontsize")}}</small>
                    <v-icon >mdi-tune</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item>
                        <v-list-item-title link>
                          <v-btn block @click.stop="fontSize(true)">A+</v-btn>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title link>
                          <v-btn block @click.stop="fontSize()">A-</v-btn>
                        </v-list-item-title>
                    </v-list-item>
                               
                </v-list>
            </v-menu>            
      <bookmark-btn
        :value="inBookmarks"
        in-bottom-nav
        @toggle="toggleBookmarksItem"
      ></bookmark-btn>
      <v-btn @click="share()">
        <small>{{ $t("submit") }}</small>
        <v-icon>mdi-share</v-icon>
      </v-btn>
      <daawa-cart-btn
        :value="inDaawaCart"
        in-bottom-nav
        @toggle="toggleDaawaCartItem"
      ></daawa-cart-btn>
    </v-bottom-navigation>
    <v-scale-transition>
      <div v-if="hadeeth">
        <v-alert class="mt-3" border="top" color="drawerFixed" colored-border>
          <p><small>{{ hadeeth.title }}</small></p>
        </v-alert>

        <v-card
          shaped
          elevation="3"
          color="bg lighten-4"
          class="mx-auto px-4 py-1 mb-5"
        >
          <v-card-text class="pb-0">
            <base-info-component
              :hadeeth="hadeeth"
              :arabic_interface="arabic_interface"
              :languageIsoCode="$route.query.lang"
              :viewAllTranslations.sync="viewAllTranslations"
              :noteDialog.sync="showNoteDialog"
              :notePart.sync="notePart"
              :noteTrans.sync="noteTrans"
            >
              <template v-slot:view_more>
                <div v-if="!arabic_interface" class="view-more-container">
                  <v-btn
                    :outlined="viewMoreFlag"
                    @click="viewMoreArabic"
                    color="lime darken-3"
                    small
                    dark
                    class="mb-2"
                    >المزيد...</v-btn
                  >
                  <div v-if="viewMoreFlag && viewMoreData != null">
                    <div class="my-1 text-right">
                      <v-chip small class="bg lighten-3">الشرح</v-chip>
                      <p>
                        <span class="type--text">{{ viewMoreData.explanation }}</span>
                        <v-btn
                          icon
                          small
                          color="orange"
                          @click="
                            notePart = 'exp';
                            noteTrans = 'ar';
                            showNoteDialog = true;
                          "
                          ><v-icon small>mdi-alert</v-icon></v-btn
                        >
                      </p>
                    </div>
                    <v-chip small class="bg lighten-3 my-2">
                      معاني الكلمات
                    </v-chip>
                    <words-meanings
                      v-if="
                        viewMoreData &&
                          typeof viewMoreData.words_meanings != 'undefined' &&
                          viewMoreData.words_meanings.length > 0
                      "
                      :wordsMeanings="viewMoreData.words_meanings"
                    ></words-meanings>
                    <v-chip small class="bg lighten-3 my-2">الفوائد</v-chip>
                    <hints
                      v-if="
                        viewMoreData &&
                          typeof viewMoreData.hints != 'undefined' &&
                          viewMoreData.hints.length > 0
                      "
                      :hints="viewMoreData.hints"
                      :class="'text-right'"
                    ></hints>
                    <!-- <references v-if="viewMoreData && typeof viewMoreData.reference != 'undefined' && viewMoreData.reference.length > 0" :references="viewMoreData.reference"></references>-->
                  </div>
                </div>
              </template>
            </base-info-component>
          </v-card-text>
          <v-card-actions class="px-4 pb-4 flex-wrap">
            <v-btn
              :outlined="viewAllTranslations"
              color="lime darken-3"
              class="px-4"
              v-if="
                hadeeth &&
                  hadeeth.translations &&
                  hadeeth.translations.length > 0
              "
              dark
              @click="viewAllTranslations = !viewAllTranslations"
            >
              <v-icon class="me-2">mdi-translate</v-icon>
              {{ $t("show_transes") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-scale-transition>

    <v-slide-y-transition>
      <all-translations
        v-if="hadeeth && viewAllTranslations && appLangs != null"
        :hadeeth="hadeeth"
        :appLangs="appLangs"
        :noteDialog.sync="showNoteDialog"
        :notePart.sync="notePart"
        :noteTrans.sync="noteTrans"
      ></all-translations>
    </v-slide-y-transition>

    <v-expansion-panels accordion multiple v-model="openPanels">
      <v-expansion-panel
        v-if="arabic_interface && isFilledArray('words_meanings')"
      >
        <v-expansion-panel-header>{{
          $t("meanings")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <words-meanings
            :wordsMeanings="hadeeth.words_meanings"
          ></words-meanings>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="isFilledArray('hints')">
        <v-expansion-panel-header>{{ $t("fawaed") }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <hints :hints="hadeeth.hints"></hints>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <references
        v-if="arabic_interface && hadeeth && hadeeth.reference"
        :references="hadeeth.reference"
      ></references>

      <!-- <hadeeth-categories-info v-if="isFilledArray('categories')" :categoriesIds="hadeeth.categories"></hadeeth-categories-info> -->
    </v-expansion-panels>
    <div v-if="hadeeth">
      <note-dialog
        :showDialog.sync="showNoteDialog"
        :id="hadeeth.id"
        :trans="noteTrans"
        :part="notePart"
      ></note-dialog>
    </div>
  </div>
</template>

<script>
import BaseInfoComponent from "@/components/HadeethComponents/BaseInfoComponent.vue";
import WordsMeanings from "@/components/HadeethComponents/WordsMeanings.vue";
import Hints from "@/components/HadeethComponents/Hints.vue";
import References from "@/components/HadeethComponents/References.vue";
import AllTranslations from "@/components/HadeethComponents/AllTranslations.vue";
import NoteDialog from "@/components/HadeethComponents/NoteDialog.vue";

//import HadeethCategoriesInfo from '@/components/HadeethComponents/HadeethCategoriesInfo.vue';

import { EventBus } from "@/event-bus.js";

import DaawaCartToggler from "@/mixins/daawa-cart-toggler.js";
import DaawaCartBtn from "@/components/HadeethComponents/DaawaCartBtn.vue";
import BookmarkBtn from "@/components/HadeethComponents/BookmarkBtn.vue";
import MostVisitedMixin from "@/mixins/most-visited.js";
import BookmarksToggler from "@/mixins/bookmarks-toggler.js";

export default {
  mixins: [DaawaCartToggler, BookmarksToggler, MostVisitedMixin],

  components: {
    BaseInfoComponent,
    WordsMeanings,
    Hints,
    References,
    AllTranslations,
    NoteDialog,
    DaawaCartBtn,
    BookmarkBtn,
    //HadeethCategoriesInfo,
  },

  data() {
    return {
      hadeeth: null,
      viewMoreData: null,
      viewMoreFlag: false,
      openPanels: [0, 1, 2],
      viewAllTranslations: false,
      bottom: null,
      showNoteDialog: null,
      notePart: "",
      noteTrans: "",
    };
  },

  async mounted() {
    //this.formatAppLangs();
    await this.fetchHadeeth();
  },

  methods: {
    share() {
      let txt = this.hadeeth.hadeeth;
      if (this.hadeeth.explanation) {
        txt += "\n\n" + "------" + "\n\n" + this.hadeeth.explanation;
      }
      txt +=
        "\n\n" +
        "https://hadeethenc.com/" +
        this.$root.locale +
        "/browse/hadith/" +
        this.hadeeth.id;
      //console.log(txt);
      this.$root.platformManager.share(txt);
    },
    async fetchHadeeth() {
      this.$root.platformManager
        .fetchSingleHadeeth(this.$route.query.hadeeth, this.$route.query.lang)
        .then(async (response) => {
          this.hadeeth = this.$root.platformManager.formatSingleHadeeth(
            response
          );
          if (!this.hadeeth.hadeeth) {
            this.$root.goHome();
          } else {
            await this.addVisitCounter();
          }
        })
        .catch((error) => {
          console.log("error fetching the hadeeth", error);
        });
    },

    isFilledArray(index) {
      return (
        this.hadeeth && this.hadeeth[index] && this.hadeeth[index].length > 0
      );
    },

    viewMoreArabic() {
      if (this.viewMoreFlag === true) {
        this.viewMoreFlag = false;
        return;
      }

      if (this.viewMoreData != null) {
        this.viewMoreFlag = true;
        return;
      }

      this.$root.platformManager
        .fetchSingleHadeeth(this.$route.query.hadeeth, "ar")
        .then((response) => {
          this.viewMoreData = this.$root.platformManager.formatSingleHadeeth(
            response
          );
          this.viewMoreFlag = true;
        })
        .catch((error) => {
          console.log("error fetching the arabic data", error);
        });
    },

    resetHadeeth() {
      this.hadeeth = null;
      this.viewMoreData = null;
      this.viewMoreFlag = false;
      this.openPanels = [0];
      this.viewAllTranslations = false;
      this.checkBookmarksItemStatus();
      this.checkDaawaCartItemStatus()
    },
    fontSize(increase=false){
      let psize = window.localStorage.getItem("psize");
      const _r = document.querySelector(':root');
      const change = (increase)?1:-1
      
      if(! psize || isNaN(psize)){
        const _rs = getComputedStyle(_r);
        psize = _rs.getPropertyValue('--psize');
      }
      
      psize = (psize*1)+change;
      _r.style.setProperty('--psize', psize);
      
      window.localStorage.setItem("psize", psize);

    }    
  },

  computed: {
    appLangs() {
      let currLang = this.$route.query.lang;
      let filteredLangs = this.$root.languages.filter((lang) => {
        return lang.code != "ar" && lang.code != currLang;
      });
      let appL = {};
      filteredLangs.forEach((lang) => {
        appL[lang.code] = lang.native;
      });
      return appL;
    },
    arabic_interface() {
      return this.$route.query.lang == "ar";
    },

    shouldViewAllTranslations() {
      return (
        this.hadeeth && this.viewAllTranslations && this.appLangs.length > 0
      );
    },
  },

  watch: {
    async "$root.locale"(val) {
      this.resetHadeeth();
      await this.fetchHadeeth();
    },
    async "$route.query.hadeeth"(val) {
      this.resetHadeeth();
      await this.fetchHadeeth();
    },
    openPanels(newval) {
      console.log("the opened panels", newval);
    },
  },
};
</script>
