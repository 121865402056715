<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-center">
          <img src="../assets/app_logo.png" style="height:80px" />
          <!-- <small>{{$t("title")}}</small> -->
        </v-list-item-title>
        <v-list-item-subtitle class="text-center py-1">
          <b class="light--text">HadeethEnc.com</b>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        @click="goToPage(item)"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            ><b class="text-subtitle-1">{{ item.title }}</b></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Languages from "@/components/navBar/Languages";
import HadeethSearchFilter from "@/components/HadeethSearchFilter";

export default {
  data() {
    return {
      items: [],
      updatesDialog: false,
    };
  },
  mounted() {
    this.generateItems();
    EventBus.$on("daawaCartUpdated", this.generateItems);
    EventBus.$on("bookmarksUpdated", this.generateItems);
  },

  beforeDestroy() {
    EventBus.$off("daawaCartUpdated", this.generateItems);
    EventBus.$off("bookmarksUpdated", this.generateItems);
  },

  methods: {
    async generateItems() {
      const daawaCartLength = await this.getDaawaCartLength();
      const _daawaCartLength = daawaCartLength ? `(${daawaCartLength})` : "";
      const bookmarksLength = await this.getBookmarksLength();
      const _bookmarksLength = bookmarksLength ? `(${bookmarksLength})` : "";
      this.items = [
        {
          title: this.$t("main_cats"),
          route: "home",
          icon: "mdi-home",
        },
        {
          title: `${this.$t("daawa_cart")} ${_daawaCartLength}`,
          route: "cart",
          icon: "mdi-cart-outline",
        },
        {
          title: `${this.$t("latest-visited")}`,
          route: "latest_visited",
          icon: "mdi-history",
        },
        {
          title: `${this.$t("bookmarks")} ${_bookmarksLength}`,
          route: "bookmarks",
          icon: "mdi-bookmark-multiple-outline",
        },
        {
          title: this.$t("about"),
          route: "https://hadeethenc.com/" + this.$root.locale + "/home/about/",
          icon: "mdi-information",
        },

        {
          title: this.$t("contact_us"),
          route: "mailto:info@hadeethenc.com",
          icon: "mdi-email",
        },
        {
          title: "HadeethEnc.com",
          route: "https://hadeethenc.com/" + this.$root.locale,
          icon: "mdi-open-in-new",
        },
        /*
                {
                  title: this.$t('updates'),
                  route: 'updates',
                  icon: 'mdi-update'
                },
                */
      ];
    },

    goToPage(item) {
      if (
        item.route.indexOf("http") != -1 ||
        item.route.indexOf("mailto") != -1
      ) {
        window.open(item.route);
        return;
      }

      if (item.route == "updates") {
        this.updatesDialog = true;
        return;
      }

      this.$router.push({ name: item.route, query:{lang:this.$root.locale} });
    },
  },
  watch: {
    "$root.locale"(val) {
      this.generateItems();
    },
  },
};
</script>
