<template>
  <v-scale-transition>
    <v-card
      shaped
      elevation="3"
      color="bg lighten-5 type--text"
      class="mx-auto px-4 py-1 mb-5 overflow-hidden"
      @click="viewHadeethByLocale(hadeeth)"
    >
      <v-card-title
        ><p>{{ hadeeth.title }}</p></v-card-title
      >
      <v-card-subtitle class="mt-2"
        >{{ $t("lang") }}:
        <v-chip
          small
          v-for="(trans, i) in viewableTranses"
          :key="'trans' + hadeeth.id + '_' + i"
          class="mr-1 mb-1"
          @click.stop="viewHadeethWithLang(hadeeth, trans, $event)"
          >{{ nativeTrans(trans) }}</v-chip
        >
        <v-chip
          small
          outlined
          color="primary darken-3"
          class="mr-1 mb-1"
          @click.stop="viewableTransesCount = transesCount"
          v-if="transesCount > viewableTransesCount"
          >+{{ transesCount - viewableTransesCount }}</v-chip
        >
      </v-card-subtitle>
      <v-card-actions class="flex-wrap">
        <bookmark-btn
          :value="inBookmarks"
          @toggle="toggleBookmarksItem"
        ></bookmark-btn>
        <daawa-cart-btn
          :value="inDaawaCart"
          @toggle="toggleDaawaCartItem"
        ></daawa-cart-btn>
        <v-btn
          elevation="0"
          rounded
          class="px-4 flex-grow-1"
          color="bg lighten-4"
        >
          <v-icon small color="lime darken-2">{{
            $vuetify.rtl ? "mdi-arrow-left" : "mdi-arrow-right"
          }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-scale-transition>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import DaawaCartToggler from "@/mixins/daawa-cart-toggler.js";
import DaawaCartBtn from "@/components/HadeethComponents/DaawaCartBtn.vue";
import BookmarksToggler from "@/mixins/bookmarks-toggler.js";
import BookmarkBtn from "@/components/HadeethComponents/BookmarkBtn.vue";

export default {
  components: { DaawaCartBtn, BookmarkBtn },
  mixins: [DaawaCartToggler, BookmarksToggler],
  props: ["hadeeth"],

  data() {
    return {
      viewableTransesCount: 2,
      unRecognizableLanguageText: "--",
    };
  },
  computed: {
    viewableTranses() {
      return this.hadeeth.translations.slice(0, this.viewableTransesCount);
    },
    transesCount() {
      return this.hadeeth.translations.length;
    },
    hadeethId() {
      return this.hadeeth?.id;
    },
  },
  watch: {
    viewableTranses(val) {
      //console.log(val)
    },
  },
  methods: {
    nativeTrans(trans) {
      if (
        typeof this.$root.languages == "undefined" ||
        !this.$root.languages ||
        this.$root.languages.length == 0
      ) {
        return this.unRecognizableLanguageText;
      }

      for (let i = 0; i < this.$root.languages.length; i++) {
        if (this.$root.languages[i].code == trans) {
          return this.$root.languages[i].native;
        }
      }

      return this.unRecognizableLanguageText;
    },

    viewHadeethWithLang(hadeeth, isoCode, event) {
      if (event.target.innerText == this.unRecognizableLanguageText) {
        return;
      }

      this.$router.push({
        name: "hadeeth",
        query: {
          hadeeth: hadeeth.id,
          lang: isoCode,
        },
      });
    },

    viewHadeethByLocale(hadeeth) {
      this.$router.push({
        name: "hadeeth",
        query: {
          hadeeth: hadeeth.id,
          lang: this.$i18n.locale,
        },
      });
    },
  },
};
</script>
