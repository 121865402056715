<template>
    <v-card class="bg lighten-4">
        <v-card-text>
            <v-simple-table class="bg lighten-4">
                <thead>
                    <tr>
                        <th class="text-right">الكلمة</th>
                        <th class="text-right">المعنى</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in wordsMeanings" :key="'meaning_' + i">
                        <td class="text-right"><p>{{ item.word }}</p></td>
                        <td class="text-right"><p>{{ item.meaning }}</p></td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>
    </v-card>
           
</template>

<script>
export default {
    props: ['wordsMeanings']
}
</script>
