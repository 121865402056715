var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-3",staticStyle:{"width":"100%"}},[_c('v-expansion-panels',{attrs:{"accordion":"","multiple":""}},_vm._l((_vm.filteredTranses),function(trans){return _c('v-expansion-panel',{key:trans,staticClass:"bg lighten-4"},[_c('v-expansion-panel-header',{on:{"click":function($event){return _vm.loadContentIfNotLoaded(trans)}}},[_vm._v(_vm._s(typeof _vm.appLangs[trans] != "undefined" ? _vm.appLangs[trans] : "unknown language to the server"))]),_c('v-expansion-panel-content',[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(!_vm.translations[trans]),expression:"!translations[trans]"}],attrs:{"color":"primary","indeterminate":""}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.translations[trans] && _vm.translations[trans].hadeeth),expression:"translations[trans] && translations[trans].hadeeth"}],class:{
            'my-5': true,
            'text-left': !_vm.isRtl(trans),
            'text-right': _vm.isRtl(trans),
          }},[(_vm.translations[trans])?_c('p',[_vm._v(" "+_vm._s(_vm.translations[trans].hadeeth)+" "),_c('v-btn',{attrs:{"icon":"","small":"","color":"orange"},on:{"click":function($event){_vm._notePart = 'hadith';
                _vm._noteTrans = trans;
                _vm._noteDialog = true;}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-alert")])],1)],1):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.translations[trans] && _vm.translations[trans].explanation),expression:"translations[trans] && translations[trans].explanation"}],class:{
            'my-5': true,
            'text-left': !_vm.isRtl(trans),
            'text-right': _vm.isRtl(trans),
          }},[_c('v-chip',{attrs:{"color":"bg lighten-3","small":""}},[_vm._v(_vm._s(_vm.$t("exp")))]),(_vm.translations[trans])?_c('p',[_vm._v(" "+_vm._s(_vm.translations[trans].explanation)+" "),_c('v-btn',{attrs:{"icon":"","small":"","color":"orange"},on:{"click":function($event){_vm._notePart = 'exp';
                _vm._noteTrans = trans;
                _vm._noteDialog = true;}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-alert")])],1)],1):_vm._e()],1),(
            _vm.translations[trans] &&
              _vm.translations[trans].hints &&
              _vm.translations[trans].hints.length
          )?_c('hints',{class:{
            'my-5': true,
            'text-left': !_vm.isRtl(trans),
            'text-right': _vm.isRtl(trans),
          },attrs:{"hints":_vm.translations[trans].hints,"forceArabic":false}}):_vm._e()],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }