import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import i18n from "./i18n";
import PlatformManager from "./PlatformManager.js";
import momentLocales from "./moment-locales";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "./assets/custom.css";
import "./scss/global.scss";

import "./plugins/local-forage.js";
import "./plugins/vue-clipboard2.js";
import "./plugins/vue-lottie.js";
import "./plugins/vue-moment.js";

//import dbVersions from "@/mobile-data-service/dbVersions";
import Confirm from "./confirm.js";
Vue.prototype.$confirm = Confirm;
// import Langs from '../src/assets/langs.json';
Vue.config.productionTip = false;

// Mixins
import GlobalDaawaCart from "./mixins/daawa-cart";
Vue.mixin(GlobalDaawaCart);
import GlobalBookmarks from "./mixins/bookmarks";
Vue.mixin(GlobalBookmarks);
import CommonMixins from "./mixins/common";
Vue.mixin(CommonMixins);

const device = device || {};
const StatusBar = StatusBar || {};

//import { EventBus } from "@/event-bus.js";
if (process.env.VUE_APP_PLATFORM == "web") {
  require("./registerServiceWorker.js");
}

let AppObj = {
  vuetify,
  router,
  i18n,
  data: {
    platformManager: PlatformManager.getManager(),
    languages: [],
    langSelected: false,
    firstRun: true,
    locale: "ar",
    subCategories: {},
    showSubCategories: false,
    currActiveCategory: null,
  },
  created() {
    this.rtls = ["ar", "ur", "ps", "ug", "fa", "ku", "dv", "nko", "he", "ks", "kwh", "kv", "prs", "arc", "yi"];
    this.initUI();
  },
  mounted() {},
  watch: {
    "$route.query.lang"(val) {
      if (!val) return;
      this.changeLanguage(val);
    },
    currActiveCategory(val) {
      console.log(val);
    },
  },
  methods: {
    toggleDark() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      window.localStorage.setItem("dark", this.$vuetify.theme.dark ? "1" : ""); // localStorage store strings, so use empty string as bool false
      window.localStorage.setItem("light", this.$vuetify.theme.dark ? "" : "1"); // localStorage store strings, so use empty string as bool false

      
    },

    initUI() {
      const isLight = window.localStorage.getItem("light");
      const isDark = window.localStorage.getItem("dark");
      if (isLight) {
        this.$vuetify.theme.dark = false;
      }

      this.firstRun = !window.localStorage.getItem("runBefore");
      this.initLanguage();
      this.fetchLanguages();
      if (this.firstRun) {
        window.localStorage.setItem("runBefore", "1");
      }

      const psize = window.localStorage.getItem("psize");
      if (psize && !isNaN(psize)) {
        const _r = document.querySelector(":root");
        _r.style.setProperty("--psize", psize);
      }
    },
    initLanguage() {
      const langCode =
        this.$route.query.lang || window.localStorage.getItem("lang") || "ar";
      this.$vuetify.rtl = this.rtls.indexOf(langCode) != -1;
      this.$i18n.locale = langCode;
      this.locale = langCode;
      this.setMomentLocale();
      window.localStorage.setItem("lang", langCode);
    },
    changeLanguage(langCode) {
      //this.$root.platformManager.changeLocale(langCode);
      window.localStorage.setItem("lang", langCode);
      this.$vuetify.rtl = this.rtls.indexOf(langCode) != -1;
      this.$i18n.locale = langCode;
      this.locale = langCode;
      this.setMomentLocale();
    },
    setMomentLocale() {
      // Change vue-moment language based on selected user locale
      const langCode = this.locale;
      if (momentLocales.includes(langCode)) {
        this.$moment.locale(langCode);
      } else {
        this.$moment.locale("en");
      }
    },
    fetchLanguages() {
      console.log("fetching....");
      this.$root.platformManager
        .fetchLanguages()
        .then((response) => {
          this.$root.languages = this.$root.platformManager.formatLanguagesResponse(
            response
          );
          //EventBus.$emit('languages-loaded');
        })
        .catch((error) => {
          console.log("error fetching the languages", error);
        });
    },
    goHome() {
      this.$router.push({
        name: "categories",
        query: {
          lang: this.$root.locale,
        },
      });
    },
  },

  render: (h) => h(App),
};
function loadcss(filename) {
  var fileref = document.createElement("link");
  fileref.setAttribute("rel", "stylesheet");
  fileref.setAttribute("type", "text/css");
  fileref.setAttribute("href", filename);
  document.getElementsByTagName("head")[0].appendChild(fileref);
}
if (process.env.VUE_APP_PLATFORM == "mobile") {
  document.addEventListener("deviceready", onDeviceReady, false);
} else if (process.env.VUE_APP_PLATFORM == "web") {
  let isIOS =
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

  let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") != -1;

  if (isAndroid) {
    loadcss("./css/android.css");
  } else if (isIOS) {
    loadcss("./css/ios.css");
  } else {
    // desktop
    loadcss("./css/desktop.css");
  }
  loadcss("./css/web.css");

  new Vue(AppObj).$mount("#app");
}

function onDeviceReady() {
  new Vue(AppObj).$mount("#app");

  if (device.platform?.toLowerCase() == "android") {
    loadcss("./css/android.css");
  } else {
    loadcss("./css/ios.css");
  }
  loadcss("./css/native-app.css");

  /*
  dbVersions.initTranses();
  setTimeout(function() {
    dbVersions.checkUpdatedVersions().then(() => {
      if (dbVersions.checkLangDBUpdate(i18n.locale)) {
        Confirm.open(i18n.t("confirm.update"), i18n.t("confirm.sync_db"), {
          color: "lime darken-4",
        }).then((confirm) => {
          if (confirm) {
            dbVersions.updateDBLang(i18n.locale).then(function() {
              alert(i18n.t("confirm.sync_db_success"));
            });
          }
        });
      }
    });
  }, 3000);
  */
}
