const axios = require("axios");
const apiUrl = process.env.VUE_APP_API_BASE_URL;

export default {
  async setDaawaCart(ids = [], lang = "") {
    try {
      const _ids = ids.join(",");
      const url = `${apiUrl}/daawa/set?ids=${_ids}&language=${lang}`;
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },
  async getDaawaCart(id = "") {
    try {
      const url = `${apiUrl}/daawa/get/${id}`;
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },
  async pickHadeeths(ids = [], lang = "") {
    try {
      const _ids = ids.join(",");
      const url = `${apiUrl}/hadeeths/list?pick=${_ids}&language=${lang}`;
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },
};
