export default {
  filters: {
    arNumSuffix(counter, oneText, twoText, singleText, multipleText) {
      const n = Number(counter);
      if (n === 1) {
        return oneText;
      }
      if (n === 2) {
        return twoText;
      }
      if (n < 11 || (n > 100 && n < 111) || (n > 200 && n < 211)) {
        return `${counter} ${multipleText}`;
      }
      return `${counter} ${singleText}`;
    },
  },
};
